import { Box, Divider, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import SponsorsComponent from 'components/sponsors.component';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        bgcolor: '#2c2f2b',
        color: 'white',
        height: 'auto',
        pl: '10vw',
        px: '10vw',
      }}>
      <Box
        sx={{
          py: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Typography sx={{ display: { xs: 'none', lg: 'inline' } }}>
          © Copyright {new Date().getFullYear()} - Schachclub ML Kastellaun
        </Typography>
        <SponsorsComponent sx={{ py: { xs: 1, lg: 0 } }} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}>
          <Typography
            onClick={() => navigate('/datenschutzerklaerung')}
            sx={{ ml: 2, cursor: 'pointer' }}>
            Datenschutzerklärung
          </Typography>
          <Divider orientation="vertical" sx={{ mx: 1, bgcolor: grey[100], height: '24px' }} />
          <Typography onClick={() => navigate('/impressum')} sx={{ cursor: 'pointer' }}>
            Impressum
          </Typography>
          <Divider orientation="vertical" sx={{ mx: 1, bgcolor: grey[100], height: '24px' }} />
          <Typography onClick={() => navigate('/satzung')} sx={{ cursor: 'pointer' }}>
            Satzung
          </Typography>
          <Divider orientation="vertical" sx={{ mx: 1, bgcolor: grey[100], height: '24px' }} />
          <Typography onClick={() => navigate('/mitgliederantrag')} sx={{ cursor: 'pointer' }}>
            Mitgliederantrag
          </Typography>
          <Divider orientation="vertical" sx={{ mx: 1, bgcolor: grey[100], height: '24px' }} />
          <Typography onClick={() => navigate('/kontakt')} sx={{ cursor: 'pointer' }}>
            Kontakt
          </Typography>
        </Box>
        <Typography
          sx={{
            display: { xs: 'inline', lg: 'none' },
            mt: 1,
            textAlign: 'center',
          }}>
          © Copyright {new Date().getFullYear()} - Schachclub ML Kastellaun
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
