import { Box } from '@mui/material';
import SidebarComponent from 'components/navigation/sidebar.component';
import { useEffect, useState } from 'react';
import SidebarItem from 'dtos/sidebarItem.dto';
import DefaultPage from './default/default.page';
import { useParams } from 'react-router-dom';
import NotFoundPage from './notFound.page';

function ClubChampionshipPage() {
  const { year } = useParams<{ year?: string }>();
  const [sidebarItems, setSidebarItems] = useState<SidebarItem[]>([]);
  const [isValidYear, setIsValidYear] = useState<boolean>(true);

  function getSidebarItems() {
    const items: SidebarItem[] = [];
    items.push({
      id: 'Ehrentafel',
      link: '/vereinsmeisterschaft',
      title: 'Ehrentafel',
    });
    for (let year = 2024; year >= 1984; year--) {
      items.push({
        id: year.toString(),
        link: `/vereinsmeisterschaft/${year}`,
        title: year.toString(),
      });
    }
    return items;
  }

  useEffect(() => {
    const items = getSidebarItems();
    setSidebarItems(items);
    setIsValidYear(items.some((item) => item.id === year) || year === undefined);
  }, [year]);

  return (
    <Box display="flex" flexDirection={{ xs: 'column', md: 'row-reverse' }} gap={2}>
      <Box flex={{ xs: '0 0 auto', md: '1 1 auto' }}>
        {isValidYear ? (
          <DefaultPage
            route={year ? `/vereinsmeisterschaft/${year}` : '/vereinsmeisterschaft'}
            title={year ? `Vereinsmeisterschaft ${year}` : 'Vereinsmeisterschaft'}
          />
        ) : (
          <NotFoundPage />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: { xs: '100%', md: '200px' },
          mt: { xs: 0, md: 4 },
        }}>
        <SidebarComponent sidebarItems={sidebarItems}></SidebarComponent>
      </Box>
    </Box>
  );
}

export default ClubChampionshipPage;
