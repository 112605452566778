import { Box, TextField, Typography } from '@mui/material';
import AuthBoxComponent from 'components/input/authBox.component';
import CustomButton from 'components/input/button.component';
import api from 'functions/api';
import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function ChangePasswordPage() {
  const navigate = useNavigate();
  const [passwordChanged, setPasswordChanged] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email') as string;
    const password = data.get('password') as string;
    const newPassword = data.get('new_password') as string;
    const newConfirmedPassword = data.get('new__confirmed_password') as string;
    if (newPassword != newConfirmedPassword) setError('Die Passwörter stimmen nicht überein');
    await api
      .post('/auth/change-password', {
        email: email,
        password: password,
        newPassword: newPassword,
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setPasswordChanged(true);
        } else {
          setError('Benutzername oder Passwort falsch');
          setPasswordChanged(false);
        }
      })
      .catch((e) => {
        setError('Benutzername oder Passwort falsch');
        setPasswordChanged(false);
        console.log(e);
      });
  }

  return (
    <AuthBoxComponent
      formTitle={'Passwort Zurücksetzen'}
      submitText={'Passwort zurücksetzen'}
      handleSubmit={handleSubmit}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Addresse"
        name="email"
        autoComplete="email"
        autoFocus
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Passwort"
        type="password"
        id="password"
        autoComplete="current-password"
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="new_password"
        label="Neues Passwort"
        type="password"
        id="new_password"
        autoComplete="current-password"
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="new__confirmed_password"
        label="Passwort bestätigen"
        type="password"
        id="new__confirmed_password"
        autoComplete="current-password"
      />
      {passwordChanged && (
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
          <Typography>Das Passwort wurde geändert</Typography>
          <CustomButton sx={{ display: 'flex' }} onClick={() => navigate('/admin-login')}>
            Zurück zum login
          </CustomButton>
        </Box>
      )}
      {error && <Typography sx={{ color: 'red' }}>{error}</Typography>}
    </AuthBoxComponent>
  );
}

export default ChangePasswordPage;
