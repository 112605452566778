import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import SidebarItem from 'dtos/sidebarItem.dto';
import { Link } from 'react-router-dom';

interface SidebarComponentProps {
  sidebarTitle?: string;
  sidebarItems: SidebarItem[];
}

function SidebarComponent({ sidebarTitle, sidebarItems }: SidebarComponentProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 'inherit',
        bgcolor: grey[600],
        gap: 2,
        py: 2,
      }}>
      {sidebarTitle && <Typography>{sidebarTitle}</Typography>}
      {sidebarItems.map((item) => (
        <Link key={item.id} to={item.link} className="navbar-link">
          <Typography color="white">{item.title}</Typography>
        </Link>
      ))}
    </Box>
  );
}

export default SidebarComponent;
