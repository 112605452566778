import { Box, Paper, Avatar, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { FormEvent, ReactNode } from 'react';
import CustomButton from './button.component';
import { grey } from '@mui/material/colors';

interface AuthBoxProps {
  formTitle: string;
  submitText: string;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  children?: ReactNode;
}

function AuthBoxComponent({ formTitle, submitText, handleSubmit, children }: AuthBoxProps) {
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '3%',
      }}>
      <Paper
        elevation={3}
        sx={{
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '75%',
          maxWidth: '500px',
        }}>
        <Avatar
          sx={{
            m: 1,
            bgcolor: grey[700],
            '&:hover': {
              bgcolor: grey[800],
            },
            color: 'white',
          }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {formTitle}
        </Typography>
        {children}
        <CustomButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, maxWidth: '50%' }}>
          {submitText}
        </CustomButton>
      </Paper>
    </Box>
  );
}

export default AuthBoxComponent;
