import { Box, Typography, Link } from '@mui/material';
import { eventOptions } from './eventOptions';
import EventDto from 'dtos/event.dto';
import { Delete, Edit } from '@mui/icons-material';
import CustomIconButton from 'components/input/iconButton.component';
import { useNavigate } from 'react-router-dom';
import api from 'functions/api';
import { AuthContext } from 'contexts/auth.context';
import { useContext } from 'react';
import { DialogContext } from 'contexts/dialog.context';

export interface EventProps {
  event: EventDto;
  currentDate: Date;
  onDelete: (id: string) => void;
}

function Event({ event, currentDate, onDelete }: EventProps) {
  const navigate = useNavigate();
  const { userHasRole } = useContext(AuthContext);
  const { setDialogProps } = useContext(DialogContext);

  const eventStartTime = event.start_date_time.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
  const eventEndTime = event.end_date_time.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  const startedToday =
    currentDate.toLocaleDateString() === event.start_date_time.toLocaleDateString();
  const endsToday = currentDate.toLocaleDateString() === event.end_date_time.toLocaleDateString();

  let eventTime = '';
  if (
    (eventStartTime === '00:00' && !endsToday) ||
    (!startedToday && !endsToday) ||
    (eventEndTime === '00:00' && endsToday)
  ) {
    eventTime = 'Ganztägig';
  } else if ((eventStartTime === '00:00' && endsToday) || (!startedToday && endsToday)) {
    eventTime = `Bis ${eventEndTime}`;
  } else if (
    eventEndTime === '00:00' ||
    (startedToday && !endsToday && eventStartTime !== '00:00')
  ) {
    eventTime = `Ab ${eventStartTime}`;
  } else {
    eventTime = `${eventStartTime} - ${eventEndTime}`;
  }

  function handleEdit() {
    navigate('/termine/verwalten/' + event.id);
  }

  async function handleDelete(confirm: boolean = false) {
    if (!confirm) {
      setDialogProps({
        open: true,
        message: 'Event wirklich löschen?',
        content: event.title,
        onConfirm: () => handleDelete(true),
      });
    } else {
      await api.delete('/events/' + event.id);
      onDelete(event.id);
    }
  }

  function isValidUrl(string: string) {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  }

  function getGoogleMapsLink(address: string) {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
  }

  function insertSoftHyphens(text: string): string {
    return text.replace(
      /(\w{10,})/g, // Insert soft hyphens only for words longer than 10 characters
      (word) => word.split('').join('\u00AD'),
    );
  }

  return (
    <Box
      key={event.id}
      sx={{
        bgcolor: eventOptions.find((option) => option.name === event.type)?.color,
        color: 'black',
        padding: 0.5,
        marginBottom: 1,
        borderRadius: 1,
        textAlign: 'left',
        position: 'relative',
        // '&:hover .icon-bar': {
        //   display: 'flex',
        // },
      }}>
      <Typography
        variant="body2"
        sx={{ fontWeight: 'bold' }}
        dangerouslySetInnerHTML={{ __html: insertSoftHyphens(event.title) }}
      />
      <Typography fontSize={12}>{event.description}</Typography>
      {isValidUrl(event.location) ? (
        <Link
          href={event.location}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ fontSize: 12, wordBreak: 'break-word' }}>
          <Typography fontSize={12}>{event.location}</Typography>
        </Link>
      ) : (
        <Link
          href={getGoogleMapsLink(event.location)}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ fontSize: 12, wordBreak: 'break-word' }}>
          <Typography fontSize={12}>{event.location}</Typography>
        </Link>
      )}
      <Typography
        variant="caption"
        sx={{
          display: 'block',
          fontSize: '0.75rem',
        }}>
        {eventTime}
      </Typography>
      {userHasRole('editor') && (
        <Box
          className="icon-bar"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 1,
          }}>
          <CustomIconButton
            size="small"
            onClick={() => handleEdit()}
            sx={{
              bgcolor: 'success.main',
              '&:hover': {
                bgcolor: 'success.dark',
              },
            }}>
            <Edit fontSize="small" />
          </CustomIconButton>
          <CustomIconButton
            size="small"
            onClick={() => handleDelete()}
            sx={{
              bgcolor: 'error.main',
              '&:hover': {
                bgcolor: 'error.dark',
              },
            }}>
            <Delete fontSize="small" />
          </CustomIconButton>
        </Box>
      )}
    </Box>
  );
}

export default Event;
