import api from 'functions/api';

export async function fileUpload(file: File) {
  console.info('Uploading file:', file);

  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await api.post('/file/upload', formData);
    const data = response.data;
    console.log(data);
    return {
      src: data.fileUrl,
      filename: data.filename,
    };
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
}

export async function imageUpload(file: File) {
  console.info('Uploading image:', file);

  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await api.post('/file/upload', formData);
    console.log(response);
    const data = response.data;
    console.log(data);
    return {
      src: data.fileUrl,
      alt: data.filename,
      width: 480,
    };
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
}
