import { Box } from '@mui/material';
import Calendar from 'components/calendar/calendar.component';
import DefaultPage from 'pages/default/default.page';

function EventsPage() {
  return (
    <Box>
      <DefaultPage route="/termine" title="Termine" />
      <Calendar />
    </Box>
  );
}

export default EventsPage;
