import axios from 'axios';

// Create an Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '',
  withCredentials: true,
});

// Request interceptor to add the access token to headers
api.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Response interceptor to handle token refresh
api.interceptors.response.use(
  (response) => {
    if (response.status === 201 && response.data.status && response.data.status !== 201) {
      // If the API returns a custom status, we use that instead
      response.status = response.data.status;
      response.statusText = response.data.statusText || response.data.message || 'Error';
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        await axios.get(process.env.REACT_APP_API_URL + '/auth/refresh' || '', {
          withCredentials: true,
        });
        return api(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  },
);

export default api;
