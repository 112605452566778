import { useEffect } from 'react';
import { Box } from '@mui/material';
import NewsView from 'components/news/newsView.component';
import { useNavigate } from 'react-router-dom';
import CustomButton from 'components/input/button.component';
import DefaultPage from './default/default.page';
import Calendar from 'components/calendar/calendar.component';
import TitleComponent from 'components/text/title.component';

function Home() {
  useEffect(() => {
    document.title = 'Schachclub ML Kastellaun - Home';
  });

  const navigate = useNavigate();

  return (
    <Box sx={{ mb: 2 }}>
      <DefaultPage route="/" />
      <NewsView panelCount={4} />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 10 }}>
        <CustomButton onClick={() => navigate('/news')} sx={{ mt: '4vh' }}>
          Mehr laden
        </CustomButton>
      </Box>
      <TitleComponent text="Termine"></TitleComponent>
      <Calendar />
    </Box>
  );
}

export default Home;
