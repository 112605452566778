import { PostDto } from 'dtos/post.dto';
import { deletePost, getPosts } from 'functions/post.functions';
import { useContext, useEffect, useState } from 'react';
import NewsPanel from './newsPanel.component';
import { Box, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import TitleComponent from 'components/text/title.component';
import { useNavigate } from 'react-router-dom';
import { Add, FilterAlt, FilterAltOff } from '@mui/icons-material';
import { AuthContext } from 'contexts/auth.context';
import { DialogContext } from 'contexts/dialog.context';
import CustomIconButton from 'components/input/iconButton.component';
import TagSelection from 'components/input/tagSelection.component';
import CustomButton from 'components/input/button.component';
import { grey } from '@mui/material/colors';

interface NewsViewProps {
  newsTitle?: string;
  panelCount?: number;
  showReadMore?: boolean;
  postFilter?: string[];
  showTagSelection?: boolean;
}

function NewsView({
  newsTitle = 'News',
  panelCount = 4,
  showReadMore = false,
  postFilter,
  showTagSelection = false,
}: NewsViewProps) {
  const { userHasRole } = useContext(AuthContext);
  const { setDialogProps } = useContext(DialogContext);
  const [posts, setPosts] = useState<PostDto[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [resetKey, setResetKey] = useState(0);
  const [tagSelection, setTagSelection] = useState<string[]>([]);
  const [isReadMoreDisabled, setIsReadMoreDisabled] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    if (isSmallScreen && tagSelection.length > 0 && showTagSelection) {
      setIsFilterVisible(true);
    }
  }, [isSmallScreen]);

  useEffect(() => {
    setTagSelection(postFilter || []);
  }, [postFilter]);

  useEffect(() => {
    setIsLoading(true);

    getPosts({ count: panelCount, tags: tagSelection }).then((data) => {
      console.debug(data);
      if (data) {
        setPosts(data);
      }
      setIsLoading(false);
    });
  }, [resetKey, panelCount, tagSelection]);

  function handleDelete(id: string, confirmed: boolean = false) {
    if (!confirmed) {
      setDialogProps({
        open: true,
        message: 'Beitrag wirklich löschen?',
        content: posts.find((post) => post.id === id)?.title,
        onConfirm: () => handleDelete(id, true),
      });
    } else {
      deletePost(id).then(() => setResetKey(resetKey + 1));
    }
  }

  function handleLoadMore(limit = 8) {
    console.log('Loading more posts...');
    setIsLoading(true);
    const lastPost = posts[posts.length - 1];
    getPosts({ tags: tagSelection, count: limit, olderThen: lastPost.date }).then((newPosts) => {
      if (newPosts.length < limit) {
        setIsReadMoreDisabled(true);
      }
      setPosts([...posts, ...newPosts]);
      setIsLoading(false);
    });
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {(posts.length > 0 || userHasRole('editor')) && <TitleComponent text={newsTitle} />}
        {isSmallScreen && showTagSelection && (
          <CustomIconButton
            onClick={() => {
              setIsFilterVisible(!isFilterVisible);
              setTagSelection([]);
            }}
            sx={{
              bgcolor: grey[700],
              '&:hover': {
                bgcolor: grey[800],
              },
              fontSize: '2rem',
              ml: '10px',
            }}>
            {isFilterVisible ? <FilterAltOff /> : <FilterAlt />}
          </CustomIconButton>
        )}
        {userHasRole('editor') && (
          <CustomIconButton
            onClick={() => navigate('/news/verwalten', { state: { tags: postFilter } })}
            sx={{
              bgcolor: 'success.main',
              '&:hover': {
                bgcolor: 'success.dark',
              },
              fontSize: '2rem',
              ml: '10px',
            }}>
            <Add />
          </CustomIconButton>
        )}
      </Box>
      {showTagSelection && (!isSmallScreen || isFilterVisible || tagSelection.length > 0) ? (
        <TagSelection
          tags={tagSelection}
          setTags={setTagSelection}
          sx={{ mb: 3, display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}
        />
      ) : null}
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      ) : posts.length > 0 ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                sm: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
                xl: 'repeat(4, 1fr)',
              },
              gap: 2,
            }}>
            {posts.map((post) => (
              <NewsPanel
                key={post.id}
                id={post.id}
                date={post.date}
                tags={post.tags}
                summary={post.summary}
                title={post.title}
                previewImage={post.preview_image}
                handleDelete={handleDelete}
              />
            ))}
          </Box>
          {showReadMore && !isReadMoreDisabled ? (
            <CustomButton onClick={() => handleLoadMore()} sx={{ mt: '4vh' }}>
              Mehr laden
            </CustomButton>
          ) : null}
        </Box>
      ) : (
        userHasRole('editor') && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h5">
              Aktuell sind keine {newsTitle || 'Beiträge'} vorhanden. Komme später wieder!
            </Typography>
          </Box>
        )
      )}
    </Box>
  );
}

export default NewsView;
