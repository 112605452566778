export interface CalendarStyles {
  header: string;
  day: string;
}

export const calendarStyles: { [key: string]: CalendarStyles } = {
  Januar: {
    header: '#64B5F6', // Light blue
    day: 'linear-gradient(135deg, #E3F2FD 0%, #BBDEFB 100%)', // Pale gradient blue
  },
  Februar: {
    header: '#BA68C8', // Light purple
    day: 'linear-gradient(135deg, #F3E5F5 0%, #E1BEE7 100%)', // Pale gradient purple
  },
  März: {
    header: '#FF8A80', // Light red
    day: 'linear-gradient(135deg, #FFE5E5 0%, #FFB3B3 100%)', // Pale gradient red
  },
  April: {
    header: '#AED581', // Light lime
    day: 'linear-gradient(135deg, #F1F8E9 0%, #DCEDC8 100%)', // Pale gradient lime
  },
  Mai: {
    header: '#81C784', // Light green
    day: 'linear-gradient(135deg, #E8F5E9 0%, #C8E6C9 100%)', // Pale gradient green
  },
  Juni: {
    header: '#4DD0E1', // Light cyan
    day: 'linear-gradient(135deg, #E0F7FA 0%, #B2EBF2 100%)', // Pale gradient cyan
  },
  Juli: {
    header: '#9575CD', // Light indigo
    day: 'linear-gradient(135deg, #EDE7F6 0%, #D1C4E9 100%)', // Pale gradient indigo
  },
  August: {
    header: '#FF8A65', // Light coral
    day: 'linear-gradient(135deg, #FBE9E7 0%, #FFCCBC 100%)', // Pale gradient coral
  },
  September: {
    header: '#FFF176', // Light yellow
    day: 'linear-gradient(135deg, #FFFDE7 0%, #FFF9C4 100%)', // Pale gradient yellow
  },
  Oktober: {
    header: '#FFB74D', // Light orange
    day: 'linear-gradient(135deg, #FFF3E0 0%, #FFE0B2 100%)', // Pale gradient orange
  },
  November: {
    header: '#90A4AE', // Light blue-grey
    day: 'linear-gradient(135deg, #ECEFF1 0%, #CFD8DC 100%)', // Pale gradient blue-grey
  },
  Dezember: {
    header: '#FF8A80', // Light red
    day: 'linear-gradient(135deg, #FFE5E5 0%, #FFB3B3 100%)', // Pale gradient red
  },
};
