import { ArrowBackIosRounded } from '@mui/icons-material';
import { Container, Paper, Stack } from '@mui/material';
import CustomButton from 'components/input/button.component';
import CustomIconButton from 'components/input/iconButton.component';
import { ReactNode } from 'react';

export interface FormProps {
  name: string;
  submitText: string;
  handleSubmit: () => void;
  deleteText?: string;
  handleDelete: () => void;
  handleRevert: () => void;
  handleCancel: () => void;
  children?: ReactNode;
}

function Form({
  submitText,
  handleSubmit,
  deleteText,
  handleDelete,
  handleRevert,
  handleCancel,
  children,
}: FormProps) {
  return (
    <Container>
      <Paper elevation={5}>
        <Stack spacing={2} sx={{ padding: '2%' }}>
          <CustomIconButton
            onClick={handleCancel}
            sx={{
              width: 'fit-content',
              bgcolor: 'error.main',
              '&:hover': {
                bgcolor: 'error.dark',
              },
            }}>
            <ArrowBackIosRounded />
          </CustomIconButton>
          {children}
          <Container
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: '1vw',
            }}>
            {deleteText && (
              <CustomButton
                onClick={handleDelete}
                sx={{
                  flexGrow: 1,
                  bgcolor: 'error.main',
                  '&:hover': {
                    bgcolor: 'error.dark',
                  },
                  color: 'black',
                }}>
                {deleteText}
              </CustomButton>
            )}
            <CustomButton
              onClick={handleRevert}
              sx={{
                flexGrow: 1,
                bgcolor: 'warning.main',
                '&:hover': {
                  bgcolor: 'warning.dark',
                },
                color: 'black',
              }}>
              Eingaben Verwerfen
            </CustomButton>
            <CustomButton
              onClick={handleSubmit}
              sx={{
                flexGrow: 1,
                bgcolor: 'success.main',
                '&:hover': {
                  bgcolor: 'success.dark',
                },
                color: 'black',
              }}>
              {submitText}
            </CustomButton>
          </Container>
        </Stack>
      </Paper>
    </Container>
  );
}

export default Form;
