import { TextField, Typography } from '@mui/material';
import AuthBoxComponent from 'components/input/authBox.component';
import api from 'functions/api';
import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function RequestPasswordResetPage() {
  const navigate = useNavigate();
  const [codeSent, setCodeSent] = useState<boolean>(false);

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (codeSent) {
      navigate('/admin-login');
    } else {
      const data = new FormData(event.currentTarget);
      const email = data.get('email') as string;
      try {
        await api.post('/auth/reset-password-request', { email: email });
      } catch (e) {
        console.log(e);
      } finally {
        setCodeSent(true);
      }
    }
  }

  return (
    <AuthBoxComponent
      formTitle={'Passwort Vergessen'}
      submitText={codeSent ? 'Zurück zum login' : 'Code Anfordern'}
      handleSubmit={handleSubmit}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Addresse"
        name="email"
        autoComplete="email"
        autoFocus
      />
      {codeSent && (
        <Typography sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
          Der Code zum zurücksetzen des Passworts wurde an die angegebene Email versendet
        </Typography>
      )}
    </AuthBoxComponent>
  );
}

export default RequestPasswordResetPage;
