import api from 'functions/api';
import { PostDto } from 'dtos/post.dto';

export interface PostFilterDto {
  tags?: string[];
  olderThen?: string;
  count?: number;
}

export async function createPost(postContent: PostDto) {
  try {
    const response = await api.post('/post/create', postContent);
    console.log('Created post');
    return response.data;
  } catch (error) {
    console.error('Error while creating post', error);
    throw error;
  }
}

export async function updatePost(id: string, content: PostDto) {
  try {
    const response = await api.patch('/post/update', content, {
      params: { id },
    });
    console.log('Updated post with ID: ', id);
    return response.data;
  } catch (error) {
    console.error('Error while updating post', error);
    throw error;
  }
}

export async function getPosts(filter?: PostFilterDto): Promise<PostDto[]> {
  try {
    const response = await api.get('/post', {
      params: {
        ...(filter?.count && { count: filter.count.toString() }),
        ...(filter?.tags && filter.tags.length > 0 && { tags: filter.tags.join(',') }),
        ...(filter?.olderThen && { olderThen: filter.olderThen }),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error while fetching posts', error);
    throw error;
  }
}

export async function getPostById(id: string): Promise<PostDto | null> {
  try {
    const response = await api.get('/post', {
      params: { id },
    });
    return response.data;
  } catch (error) {
    console.error('Error while fetching post', error);
    throw error;
  }
}

export async function deletePost(postId: string) {
  try {
    const response = await api.delete('/post/delete', {
      params: { id: postId },
    });
    console.log('Deleted post with ID: ', postId);
    return response.data;
  } catch (error) {
    console.error('Error while deleting post', error);
    throw error;
  }
}
