import api from 'functions/api';
import { UserDto } from 'dtos/user.dto';

export async function login(email: string, password: string): Promise<UserDto> {
  console.log('login', email);
  const response = await api.post('/auth/signin', { email, password });
  const user = response.data;
  console.log('Logged in', user);
  return user;
}

export async function logout(): Promise<boolean> {
  await api.get('/auth/logout');
  console.log('Logged out');
  return true;
}

export async function authenticate(): Promise<UserDto | null> {
  try {
    const response = await api.get('/auth/refresh');
    const user = response.data;
    console.log('Authenticated', user.email);
    return user;
  } catch (error) {
    console.error('Error while authenticating', error);
    return null;
  }
}
