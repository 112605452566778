import { Box, List, ListItemButton, ListItemText, Collapse, Divider } from '@mui/material';
import { NavDropdown, navStructure } from '../../config/navbarItems';
import { useContext, useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { AuthContext } from 'contexts/auth.context';
import SocialIconsList from './socialIconsList.component';
import { grey } from '@mui/material/colors';

interface NavbarMobileProps {
  setIsMobileNavOpen: (value: boolean) => void;
}

function NavbarMobile({ setIsMobileNavOpen }: NavbarMobileProps) {
  const [openDropdowns, setOpenDropdowns] = useState<Record<string, boolean>>({});
  const { userHasRole } = useContext(AuthContext);

  function handleClick(key: string) {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  }

  function renderNavDropdown(key: string, value: NavDropdown, layer: number = 0) {
    return (
      (!value.admin || userHasRole('editor')) && (
        <Box key={key}>
          <Divider sx={{ bgcolor: grey[800] }} />
          <ListItemButton
            key={key}
            onClick={() => handleClick(key)}
            sx={{ marginLeft: 2 * layer + '%' }}>
            <ListItemText primary={value.title} />
            {openDropdowns[key] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openDropdowns[key]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {Object.entries(value.dropdown).map(([key, value]) => {
                return (
                  (!value.admin || userHasRole('editor')) &&
                  ('dropdown' in value ? (
                    renderNavDropdown(key, value, layer + 1)
                  ) : (
                    <Link key={key} to={value.link} className="navbar-link">
                      <Divider sx={{ bgcolor: grey[800] }} />
                      <ListItemButton
                        onClick={() => setIsMobileNavOpen(false)}
                        sx={{ marginLeft: 2 * (layer + 1) + '%', whiteSpace: 'nowrap' }}>
                        <ListItemText primary={value.title} />
                      </ListItemButton>
                    </Link>
                  ))
                );
              })}
            </List>
          </Collapse>
        </Box>
      )
    );
  }

  return (
    <Box
      sx={{
        display: { xs: 'inline', lg: 'none' },
        justifyContent: 'flex-end',
        mt: '10px',
      }}>
      <Divider sx={{ bgcolor: grey[800] }} />
      <Box sx={{ pt: 1, pb: 0.5, display: 'flex', justifyContent: 'center', gap: 5 }}>
        <SocialIconsList />
      </Box>
      <Divider sx={{ bgcolor: grey[800] }} />

      <List style={{ paddingTop: 0, paddingBottom: 0 }}>
        {Object.entries(navStructure).map(([key, value], index) => {
          if ('dropdown' in value) {
            return renderNavDropdown(key, value);
          } else {
            return (
              (!value.admin || userHasRole('editor')) && (
                <Link key={key} to={value.link} className="navbar-link">
                  {index != 0 && <Divider sx={{ bgcolor: grey[800] }} />}
                  <ListItemButton onClick={() => setIsMobileNavOpen(false)}>
                    <ListItemText primary={value.title} />
                  </ListItemButton>
                </Link>
              )
            );
          }
        })}
      </List>
    </Box>
  );
}

export default NavbarMobile;
