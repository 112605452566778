import { Box } from '@mui/material';
import CustomButton from 'components/input/button.component';
import TitleComponent from 'components/text/title.component';
import { AuthContext } from 'contexts/auth.context';
import api from 'functions/api';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface NotFoundPageProps {
  title?: string;
}

function NotFoundPage({ title }: NotFoundPageProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { userHasRole } = useContext(AuthContext);

  async function createPage() {
    let pageTitle = title ?? location.pathname.split('/').pop();
    if (pageTitle) {
      pageTitle = pageTitle
        .replace('-', ' ')
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    } else {
      pageTitle = 'Neue Seite';
    }
    await api.post('/pages', {
      path: location.pathname,
      title: pageTitle,
      layout: 'default',
      admin_only: false,
    });
    navigate(0);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        flexDirection: 'column',
      }}>
      <TitleComponent text="Schachmatt!" mb={'2vh'} />
      <TitleComponent text="Hier geht es nicht weiter" mt={0} mb={'2vh'} />
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <CustomButton onClick={() => navigate('/')}>Zurück zur Homepage</CustomButton>
        {userHasRole('editor') && (
          <CustomButton
            onClick={async () => createPage()}
            sx={{
              flexGrow: 1,
              bgcolor: 'success.main',
              '&:hover': {
                bgcolor: 'success.dark',
              },
              mt: 2,
            }}>
            Seite Erstellen
          </CustomButton>
        )}
      </Box>
    </Box>
  );
}

export default NotFoundPage;
