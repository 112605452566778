import { Box, BoxProps } from '@mui/material';

function ButtonControlBar(props: BoxProps) {
  return (
    <Box
      {...props}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: { xs: 'column', sm: 'row' },
        gap: '1vw',
        mb: '4vh',
        ...props.sx,
      }}
    />
  );
}

export default ButtonControlBar;
