import { Box, Button, Typography } from '@mui/material';
import RteEditorComponent from 'components/rte-editor/rte-editor.component';
import TitleComponent from 'components/text/title.component';
import { PostDto } from 'dtos/post.dto';
import { deletePost, getPostById } from 'functions/post.functions';
import { AuthContext } from 'contexts/auth.context';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DialogContext } from 'contexts/dialog.context';

interface DetailedNewsViewProps {
  id: string;
}

function DetailedNewsView({ id }: DetailedNewsViewProps) {
  const { userHasRole } = useContext(AuthContext);
  const { setDialogProps } = useContext(DialogContext);
  const [post, setPost] = useState<PostDto>({
    id: '',
    title: '',
    date: '',
    summary: '',
    content: '',
    preview_image: '',
    tags: [],
  });

  const navigate = useNavigate();

  useEffect(() => {
    getPostById(id).then((post) =>
      setPost(
        post || {
          id: '',
          title: '',
          date: '',
          summary: '',
          content: '',
          preview_image: '',
          tags: [],
        },
      ),
    );
  }, []);

  async function handleDelete(confirm: boolean = false) {
    if (!confirm) {
      setDialogProps({
        open: true,
        message: 'Beitrag wirklich löschen?',
        content: post.title,
        onConfirm: () => handleDelete(true),
      });
    } else {
      await deletePost(post.id);
      navigate(-1);
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {post.id ? (
        <>
          {userHasRole('editor') && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: '1vw',
                mt: '4vh',
              }}>
              <Button
                onClick={() => navigate(`/news/verwalten/${id}`)}
                sx={{
                  flexGrow: 1,
                  bgcolor: 'success.main',
                  '&:hover': {
                    bgcolor: 'success.dark',
                  },
                  color: 'black',
                  maxWidth: '200px',
                }}>
                Beitrag Bearbeiten
              </Button>
              <Button
                onClick={() => handleDelete()}
                sx={{
                  flexGrow: 1,
                  bgcolor: 'error.main',
                  '&:hover': {
                    bgcolor: 'error.dark',
                  },
                  color: 'black',
                  maxWidth: '200px',
                }}>
                Beitrag Löschen
              </Button>
            </Box>
          )}
          <TitleComponent text={post.title} mb={'1vh'} sx={{ textAlign: 'center' }} />
          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '0.5rem' }}>
            {post.tags.map((tag, index) => (
              <Fragment key={tag}>
                <Typography sx={{ color: '#ce9042', fontWeight: 'bold' }}>{tag}</Typography>
                {index < post.tags.length - 1 && (
                  <Typography sx={{ color: '#ce9042', fontWeight: 'bold' }}>•</Typography>
                )}
              </Fragment>
            ))}
          </Box>
          <Typography variant="subtitle1" fontSize={20} color="#b5b5b5" mb={'5vh'}>
            {new Date(post.date).toLocaleDateString('de-DE')}
          </Typography>
          <RteEditorComponent content={post.content} editMode={false} />
        </>
      ) : (
        <>
          <TitleComponent text="Der Beitrag konnte nicht gefunden werden" mb={'2vh'} />
          <Button
            sx={{
              bgcolor: 'primary.main',
              '&:hover': {
                bgcolor: 'primary.dark',
              },
              color: 'black',
            }}
            onClick={() => navigate('/')}>
            Zurück zur Homepage
          </Button>
        </>
      )}
    </Box>
  );
}

export default DetailedNewsView;
