import { Box, BoxProps, Typography } from '@mui/material';
import EventDto from 'dtos/event.dto';
import Event from './event.component';

export interface CalendarDayProps extends BoxProps {
  day?: number;
  date?: Date;
  events?: EventDto[];
  handleDeleteEvent?: (id: string) => void;
  disabled?: boolean;
}

function CalendarDay({
  day,
  date,
  events,
  handleDeleteEvent,
  disabled,
  ...props
}: CalendarDayProps) {
  return (
    <Box
      {...props}
      sx={{
        flex: '1 0 ' + 100 / 7 + '%',
        minHeight: { xs: 'fit-content', md: 100 },
        maxWidth: { xs: '100%', md: 100 / 7 + '%' },
        display: 'flex',
        flexDirection: 'column',
        borderTop: '2px solid #000',
        padding: 1,
        position: 'relative',
        '&::before': disabled
          ? {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              zIndex: 1,
            }
          : {},
        ...props.sx,
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          color: 'black',
        }}>
        <Typography variant="body2" sx={{ display: { md: 'none' } }}>
          {date?.toLocaleDateString('de-DE', { weekday: 'long' })}
        </Typography>
        <Typography variant="body2" sx={{ marginLeft: 'auto' }}>
          {day?.toString().padStart(2, '0')}
        </Typography>
      </Box>
      <Box sx={{ flex: 1, width: '100%', marginTop: 2 }}>
        {events &&
          events.map((event) => {
            return (
              <Event
                key={event.id}
                event={event}
                currentDate={date!}
                onDelete={handleDeleteEvent!}
              />
            );
          })}
      </Box>
    </Box>
  );
}

export default CalendarDay;
