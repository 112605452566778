import { Box, Button, TextField, Typography } from '@mui/material';
import { useState, FormEvent, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'contexts/auth.context';
import AuthBoxComponent from 'components/input/authBox.component';

function AdminLoginPage() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [highlightChangePassword, setHighlightChangePassword] = useState(false);
  const { userLogin } = useContext(AuthContext);

  useEffect(() => {
    document.title = 'Schachclub ML Kastellaun - Admin Login';
  });

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email') as string;
    const password = data.get('password') as string;
    userLogin(email, password)
      .then((response) => {
        if (response) {
          navigate('/');
        } else {
          setErrorMessage('Benutzername oder Passwort falsch');
        }
      })
      .catch((error) => {
        // Status Code 420 means that the user has to reset its password
        if (error.status == 420) {
          setErrorMessage('Das Passwort muss geändert werden');
          setHighlightChangePassword(true);
        } else {
          setErrorMessage('Benutzername oder Passwort falsch');
        }
      });
  }

  return (
    <AuthBoxComponent formTitle="Admin Login" handleSubmit={handleSubmit} submitText="Login">
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Addresse"
        name="email"
        autoComplete="email"
        autoFocus
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Passwort"
        type="password"
        id="password"
        autoComplete="current-password"
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Button
          sx={{ display: 'flex', color: highlightChangePassword ? 'red' : undefined }}
          onClick={() => navigate('/admin-login/passwort-ändern')}>
          Passwort ändern
        </Button>
        <Button sx={{ display: 'flex' }} onClick={() => navigate('/admin-login/reset')}>
          Passwort zurücksetzen
        </Button>
      </Box>
      {errorMessage && (
        <Typography color="error" align="center">
          {errorMessage}
        </Typography>
      )}
    </AuthBoxComponent>
  );
}

export default AdminLoginPage;
