import { Box, Divider, Paper, Stack, Typography } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import defaultPostImage from 'assets/defaultPostImage.png';
import { useNavigate } from 'react-router-dom';
import { Fragment, useContext } from 'react';
import { AuthContext } from 'contexts/auth.context';
import CustomIconButton from 'components/input/iconButton.component';
import { grey } from '@mui/material/colors';

interface NewsPanelProps {
  id: string;
  title: string;
  previewImage?: string;
  date: string;
  tags: string[];
  summary: string;
  handleDelete: (id: string) => void;
}

function NewsPanel({
  id,
  title,
  previewImage,
  date,
  tags = [],
  summary,
  handleDelete,
}: NewsPanelProps) {
  const { userHasRole } = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <Paper
      elevation={10}
      sx={{
        width: '350px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
      <Box onClick={() => navigate(`/news/${id}`)} sx={{ cursor: 'pointer' }}>
        {/* Alternative preview option */}
        {/* <Box sx={{ height: '350px', alignContent: 'center' }}>
          <Box
            component="img"
            src={previewImage || defaultPostImage}
            alt="Post image"
            sx={{ width: '100%', maxHeight: '350px', objectFit: 'cover' }}
          />
        </Box> */}
        <Box
          component="img"
          src={previewImage || defaultPostImage}
          alt="Post image"
          sx={{ width: '100%', height: '350px', objectFit: 'cover' }}
        />
        <Stack spacing="1vh" sx={{ paddingX: '1vw', paddingY: '1vh' }}>
          <Typography variant="subtitle1" fontSize={18} color={grey[500]}>
            {new Date(date).toLocaleDateString('de-DE')}
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '0.5rem' }}>
            {tags.map((tag, index) => (
              <Fragment key={tag}>
                <Typography sx={{ color: '#ce9042', fontWeight: 'bold' }}>{tag}</Typography>
                {index < tags.length - 1 && (
                  <Typography sx={{ color: '#ce9042', fontWeight: 'bold' }}>•</Typography>
                )}
              </Fragment>
            ))}
          </Box>
          <Typography variant="h5" fontWeight="bold" sx={{ mt: '1vh' }}>
            {title}
          </Typography>
          <Typography>{summary}</Typography>
        </Stack>
      </Box>
      {userHasRole('editor') && (
        <Box>
          <Divider />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '1vh' }}>
            <CustomIconButton
              onClick={() => navigate(`/news/verwalten/${id}`)}
              sx={{
                bgcolor: 'success.main',
                '&:hover': {
                  bgcolor: 'success.dark',
                },
              }}>
              <Edit />
            </CustomIconButton>
            <CustomIconButton
              onClick={() => handleDelete(id)}
              sx={{
                bgcolor: 'error.main',
                '&:hover': {
                  bgcolor: 'error.dark',
                },
              }}>
              <Delete />
            </CustomIconButton>
          </Box>
        </Box>
      )}
    </Paper>
  );
}

export default NewsPanel;
