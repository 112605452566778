export interface EventOption {
  name: string;
  color: string;
  hoverColor: string;
}

export const eventOptions: EventOption[] = [
  { name: 'Training', color: '#FF8A80', hoverColor: '#FF5252' },
  { name: 'Turnier', color: '#FFD54F', hoverColor: '#FFC107' },
  { name: 'Mannschaftsspiel', color: '#81C784', hoverColor: '#4CAF50' },
  { name: 'Allgemein', color: '#4DB6AC', hoverColor: '#009688' },
];
