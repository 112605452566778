export type NavItem = {
  title: string;
  link: string;
  custom?: boolean;
  admin?: boolean;
};

export type NavDropdown = {
  title: string;
  link?: string;
  dropdown: NavStructure;
  custom?: boolean;
  admin?: boolean;
};

export type NavStructure = { [key: string]: NavItem | NavDropdown };

export const navStructure: NavStructure = {
  home: {
    title: 'Home',
    link: '/',
    custom: true,
  },
  news: {
    title: 'News',
    link: '/news',
    custom: true,
  },
  training: {
    title: 'Training',
    dropdown: {
      basics: {
        title: 'Grundsätzliches',
        link: '/training',
      },
      starterTraining: {
        title: 'Anfängertraining',
        link: '/anfaengertraining',
      },
      trainingVideos: {
        title: 'Videos + Taktik',
        link: '/trainingsvideos',
      },
    },
  },
  teams: {
    title: 'Teams',
    dropdown: {
      firstTeam: {
        title: 'Erste Mannschaft',
        link: '/teams/erste-mannschaft',
        custom: true,
      },
      secondTeam: {
        title: 'Zweite Mannschaft',
        link: '/teams/zweite-mannschaft',
        custom: true,
      },
      thirdTeam: {
        title: 'Dritte Mannschaft',
        link: '/teams/dritte-mannschaft',
        custom: true,
      },
      fourthTeam: {
        title: 'Vierte Mannschaft',
        link: '/teams/vierte-mannschaft',
        custom: true,
      },
      fifthTeam: {
        title: 'Fünfte Mannschaft',
        link: '/teams/fünfte-mannschaft',
        custom: true,
      },
      sixthTeam: {
        title: 'Sechste Mannschaft',
        link: '/teams/sechste-mannschaft',
        custom: true,
      },
    },
  },
  events: {
    title: 'Termine',
    link: '/termine',
  },
  results: {
    title: 'Ergebnisse',
    dropdown: {
      monthlyRapid: {
        title: 'Monatsschnellschach',
        link: '/monatsschnellschach',
      },
      clubChampionship: {
        title: 'Vereinsmeisterschaft',
        link: '/vereinsmeisterschaft',
      },
      jugendErfolge: {
        title: 'Jugenderfolge',
        link: '/jugenderfolge',
      },
    },
  },
  club: {
    title: 'Verein',
    dropdown: {
      about: {
        title: 'Über uns',
        link: '/ueber-uns',
      },
      players: {
        title: 'Unsere Spieler',
        link: '/unsere-spieler',
      },
      board: {
        title: 'Vorstand',
        link: '/vorstand',
      },
      contact: {
        title: 'Kontakt',
        link: '/kontakt',
      },
      documents: {
        title: 'Dokumente',
        dropdown: {
          memberApplication: {
            title: 'Mitgliederantrag',
            link: '/mitgliederantrag',
          },
          statue: {
            title: 'Satzung',
            link: '/satzung',
          },
          gamePermit: {
            title: 'Spielgenehmigung',
            link: '/spielgenehmigung',
          },
        },
      },
    },
  },
};

export function getPageTitle(navStructure: NavStructure, pageId: string): string | null {
  for (const key in navStructure) {
    const item = navStructure[key];
    if ('link' in item && item.link?.endsWith(pageId)) {
      return item.title;
    }
    if ('dropdown' in item) {
      const title = getPageTitle(item.dropdown, pageId);
      if (title) {
        return title;
      }
    }
  }
  return null;
}
