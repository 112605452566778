import { Box, BoxProps } from '@mui/material';
import westenergie_logo from 'assets/sponsors/westenergie_logo.png';
import lotto_rlp_logo from 'assets/sponsors/lotto_rlp_logo.png';
import kskrh_logo from 'assets/sponsors/kskrh_logo.png';
import stemmler_bus_logo from 'assets/sponsors/stemmler_bus_logo.png';

function SponsorsComponent(props: BoxProps) {
  return (
    <Box
      {...props}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
      gap={2}>
      <Box component="img" sx={{ height: '25px' }} src={westenergie_logo} alt="westenergie_logo" />
      <Box component="img" sx={{ height: '25px' }} src={lotto_rlp_logo} alt="lotto_rlp_logo" />
      <Box component="img" sx={{ height: '25px' }} src={kskrh_logo} alt="kskrh_logo" />
      <Box
        component="img"
        sx={{ height: '25px' }}
        src={stemmler_bus_logo}
        alt="stemmler_bus_logo"
      />
    </Box>
  );
}

export default SponsorsComponent;
