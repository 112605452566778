import { useEffect } from 'react';

const useSecureLinks = () => {
  useEffect(() => {
    const secureLinks = () => {
      const links = document.querySelectorAll('a[target="_blank"]');
      links.forEach((link) => {
        if (!link.getAttribute('rel')) {
          link.setAttribute('rel', 'noopener noreferrer');
        }
      });
    };

    secureLinks();

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          secureLinks();
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);
};

export default useSecureLinks;
