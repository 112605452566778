import { Box } from '@mui/material';
import CustomButton from 'components/input/button.component';
import TitleComponent from 'components/text/title.component';
import { useNavigate } from 'react-router-dom';

function NoAccessPage() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        flexDirection: 'column',
      }}>
      <TitleComponent text="Schachmatt!" mb={'2vh'} />
      <TitleComponent text="Hier geht es nicht weiter" mt={0} mb={'2vh'} />
      <CustomButton onClick={() => navigate('/')}>Zurück zur Homepage</CustomButton>
    </Box>
  );
}

export default NoAccessPage;
