import { SvgIcon } from '@mui/material';
import { Link } from 'react-router-dom';

import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { ReactComponent as LichessIcon } from 'assets/socials/lichessIcon.svg';
import { grey } from '@mui/material/colors';

function SocialIconsList() {
  const socialIconSxProps = {
    color: 'white',
    fontSize: '24px',
    mx: '8px',
    borderRadius: '20%',
    '&:hover': {
      color: grey[800],
      bgcolor: 'white',
      boxShadow: '0 0 0 5px white',
    },
  };

  return (
    <>
      <Link to="https://lichess.org/team/sc-kastellaun--friends" target="_blank">
        <SvgIcon sx={socialIconSxProps}>
          <LichessIcon />
        </SvgIcon>
      </Link>
      <Link to="https://instagram.com/schachclub_kastellaun" target="_blank">
        <InstagramIcon sx={socialIconSxProps} />
      </Link>
      <Link to="https://www.facebook.com/schachclub.kastellaun" target="_blank">
        <FacebookIcon sx={socialIconSxProps} />
      </Link>
    </>
  );
}

export default SocialIconsList;
