import { Box } from '@mui/material';
import ManageEventsForm from 'components/calendar/manageEventsForm.component';
import TitleComponent from 'components/text/title.component';
import { useLocation, useParams } from 'react-router-dom';

function ManageEventsPage() {
  const { id } = useParams<{ id?: string }>();
  const location = useLocation();
  const date = location.state?.date;

  return (
    <Box>
      <TitleComponent text={id ? 'Termin Aktualisieren' : 'Termin Erstellen'} />
      <ManageEventsForm eventId={id} start_date_time={date || undefined} />
    </Box>
  );
}

export default ManageEventsPage;
