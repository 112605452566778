import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Form from 'components/form/form.component';
import CustomDatePicker from 'components/input/datepicker.component';
import CustomTimePicker from 'components/input/timePicker.component';
import CustomTextField from 'components/input/textfield.component';
import { DialogContext } from 'contexts/dialog.context';
import dayjs from 'dayjs';
import EventDto from 'dtos/event.dto';
import api from 'functions/api';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { eventOptions } from './eventOptions';

export interface ManageEventsFormProps {
  eventId?: string;
  start_date_time?: Date;
}

function ManageEventsForm({ eventId, start_date_time }: ManageEventsFormProps) {
  const navigate = useNavigate();
  const { setDialogProps } = useContext(DialogContext);

  const defaultStartTime = dayjs(start_date_time ?? new Date())
    .minute(0)
    .second(0)
    .millisecond(0)
    .add(1, 'hour')
    .toDate();
  const defaultEndTime = dayjs(defaultStartTime).add(1, 'hour').toDate();

  const [eventData, setEventData] = useState<EventDto>({
    id: eventId ?? '',
    title: '',
    description: '',
    location: '',
    type: '',
    start_date_time: defaultStartTime,
    end_date_time: defaultEndTime,
    all_day: false,
  });
  const [initialContent, setInitialContent] = useState<EventDto>(eventData);
  const [errors, setErrors] = useState({
    title: '',
    type: '',
    start_date_time: '',
    end_date_time: '',
  });

  useEffect(() => {
    if (eventId) {
      setInitialContent(eventData);
      api.get('/events/' + eventId).then((response) => {
        if (response.data) {
          setEventData(response.data);
          setInitialContent(response.data);
        }
      });
    }
  }, []);

  async function handleSubmit() {
    const newErrors = {
      title: '',
      type: '',
      start_date_time: '',
      end_date_time: '',
    };
    setErrors(newErrors);

    if (!eventData.title) {
      newErrors.title = 'Titel ist erforderlich';
    }
    if (!eventData.type) {
      newErrors.type = 'Eventtyp ist erforderlich';
    }
    if (!eventData.start_date_time) {
      newErrors.start_date_time = 'Startzeit ist erforderlich';
    }
    if (!eventData.end_date_time) {
      newErrors.end_date_time = 'Endzeit ist erforderlich';
    }
    if (eventData.start_date_time > eventData.end_date_time) {
      newErrors.start_date_time = 'Startzeit muss vor Endzeit liegen';
    }

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error)) {
      return;
    }

    if (!eventData.id) {
      await api.post('/events', eventData);
    } else {
      await api.patch('/events/' + eventData.id, eventData);
    }
    navigate(-1);
  }

  async function handleDelete(confirm: boolean = false) {
    if (!confirm) {
      setDialogProps({
        open: true,
        message: 'Event wirklich löschen?',
        content: eventData.title,
        onConfirm: () => handleDelete(true),
      });
    } else {
      await api.delete('/events/' + eventData.id);
      navigate(-1);
    }
  }

  async function handleRevert(confirm: boolean = false) {
    if (!confirm && eventData !== initialContent) {
      setDialogProps({
        open: true,
        message: 'Eingaben wirklich verwerfen?',
        content: 'Diese Aktion kann nicht rückgängig gemacht werden!',
        onConfirm: () => handleRevert(true),
      });
    } else {
      setEventData(initialContent);
    }
  }

  async function handleCancel(confirm: boolean = false) {
    if (!confirm && eventData !== initialContent) {
      setDialogProps({
        open: true,
        message: 'Eingaben wirklich verwerfen und zurück zur Übersicht?',
        content: 'Nicht gespeicherte Änderungen gehen verloren!',
        onConfirm: () => handleCancel(true),
      });
    } else {
      navigate(-1);
    }
  }

  function handleAllDayEvent(checked: boolean): void {
    const startOfDay = dayjs(eventData.start_date_time).startOf('day').toDate();
    const endOfDay = dayjs(eventData.start_date_time).add(1, 'day').startOf('day').toDate();
    setEventData({
      ...eventData,
      all_day: checked,
      start_date_time: startOfDay,
      end_date_time: endOfDay,
    });
  }

  return (
    <Form
      name={''}
      submitText={eventId ? 'Termin Aktualisieren' : 'Termin Erstellen'}
      deleteText={eventId ? 'Termin Löschen' : undefined}
      handleSubmit={() => handleSubmit()}
      handleDelete={() => handleDelete()}
      handleRevert={() => handleRevert()}
      handleCancel={() => handleCancel()}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <CustomTextField
          label="Titel"
          value={eventData?.title}
          onChange={(e) => setEventData({ ...eventData, title: e.target.value })}
          sx={{ flex: 1 }}
          error={!!errors.title}
          helperText={errors.title}
        />
        <FormControl sx={{ flex: 1 }} error={!!errors.type}>
          <InputLabel id="event-type-label">Typ</InputLabel>
          <Select
            labelId="event-type-label"
            value={eventData.type}
            label="Typ"
            onChange={(e) => setEventData({ ...eventData, type: e.target.value })}>
            {eventOptions.map((option) => (
              <MenuItem key={option.name} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
          {errors.type && <FormHelperText>{errors.type}</FormHelperText>}
        </FormControl>
      </Box>
      <CustomTextField
        label="Beschreibung"
        multiline
        value={eventData?.description}
        onChange={(event) => setEventData({ ...eventData, description: event.target.value })}
        sx={{ minWidth: '20vw' }}
      />
      <CustomTextField
        label="Ort oder Link"
        value={eventData?.location}
        onChange={(e) => setEventData({ ...eventData, location: e.target.value })}
        sx={{ minWidth: '20vw' }}
      />
      <FormControlLabel
        sx={{ width: 'fit-content' }}
        control={
          <Checkbox
            checked={eventData.all_day}
            onChange={(value) => handleAllDayEvent(value.target.checked)}
          />
        }
        label="Ganztägig"
      />
      <Box sx={{ display: 'flex', gap: 2 }}>
        <CustomDatePicker
          label="Startdatum"
          value={dayjs(eventData.start_date_time)}
          onChange={(value) =>
            setEventData({
              ...eventData,
              start_date_time: value
                ? dayjs(eventData.start_date_time)
                    .set('year', value.year())
                    .set('month', value.month())
                    .set('date', value.date())
                    .toDate()
                : new Date(),
              end_date_time: value
                ? dayjs(eventData.end_date_time)
                    .set('year', value.year())
                    .set('month', value.month())
                    .set('date', value.date())
                    .toDate()
                : new Date(),
            })
          }
          slotProps={{
            textField: {
              error: Boolean(errors.start_date_time),
              helperText: errors.start_date_time,
            },
          }}
        />
        <CustomTimePicker
          label="Startzeit"
          value={dayjs(eventData.start_date_time)}
          disabled={eventData.all_day}
          onChange={(value) =>
            setEventData({
              ...eventData,
              start_date_time: value
                ? dayjs(eventData.start_date_time)
                    .set('hour', value.hour())
                    .set('minute', value.minute())
                    .toDate()
                : new Date(),
              end_date_time: value
                ? dayjs(eventData.start_date_time)
                    .set('hour', value.hour())
                    .set('minute', value.minute())
                    .add(1, 'hour')
                    .toDate()
                : new Date(),
            })
          }
          slotProps={{
            textField: {
              error: Boolean(errors.start_date_time),
              helperText: errors.start_date_time,
            },
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <CustomDatePicker
          label="Enddatum"
          value={dayjs(eventData.end_date_time)}
          onChange={(value) =>
            setEventData({
              ...eventData,
              end_date_time: value
                ? dayjs(eventData.end_date_time)
                    .set('year', value.year())
                    .set('month', value.month())
                    .set('date', value.date())
                    .toDate()
                : new Date(),
            })
          }
          slotProps={{
            textField: {
              error: Boolean(errors.end_date_time),
              helperText: errors.end_date_time,
            },
          }}
        />
        <CustomTimePicker
          label="Endzeit"
          value={dayjs(eventData.end_date_time)}
          disabled={eventData.all_day}
          onChange={(value) =>
            setEventData({
              ...eventData,
              end_date_time: value
                ? dayjs(eventData.end_date_time)
                    .set('hour', value.hour())
                    .set('minute', value.minute())
                    .toDate()
                : new Date(),
            })
          }
          slotProps={{
            textField: {
              error: Boolean(errors.end_date_time),
              helperText: errors.end_date_time,
            },
          }}
        />
      </Box>
    </Form>
  );
}

export default ManageEventsForm;
