import { Box, TextField, Typography } from '@mui/material';
import AuthBoxComponent from 'components/input/authBox.component';
import CustomButton from 'components/input/button.component';
import api from 'functions/api';
import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function PasswordResetPage() {
  const navigate = useNavigate();
  const resetToken = new URLSearchParams(location.search).get('resetToken');
  const [passwordResettet, setPasswordResetted] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const newPassword = data.get('new_password') as string;
    const newConfirmedPassword = data.get('new__confirmed_password') as string;
    if (newPassword != newConfirmedPassword) setError('Die Passwörter stimmen nicht überein');
    try {
      await api.post('/auth/reset-password', { resetToken: resetToken, newPassword: newPassword });
      setPasswordResetted(true);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <AuthBoxComponent
      formTitle={'Passwort Zurücksetzen'}
      submitText={'Passwort zurücksetzen'}
      handleSubmit={handleSubmit}>
      <TextField
        margin="normal"
        required
        fullWidth
        name="new_password"
        label="Neues Passwort"
        type="password"
        id="new_password"
        autoComplete="current-password"
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="new__confirmed_password"
        label="Passwort bestätigen"
        type="password"
        id="new__confirmed_password"
        autoComplete="current-password"
      />
      {passwordResettet && (
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
          <Typography>Das Passwort wurde zurückgesetzt</Typography>
          <CustomButton sx={{ display: 'flex' }} onClick={() => navigate('/admin-login')}>
            Zurück zum login
          </CustomButton>
        </Box>
      )}
      {error && <Typography sx={{ color: 'red' }}>{error}</Typography>}
    </AuthBoxComponent>
  );
}

export default PasswordResetPage;
