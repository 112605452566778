import { Box } from '@mui/material';
import CustomButton from 'components/input/button.component';
import TitleComponent from 'components/text/title.component';
import { useNavigate } from 'react-router-dom';

interface UnderConstructionPageProps {
  oldSitePath: string;
}

function UnderConstructionPage({ oldSitePath }: UnderConstructionPageProps) {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        flexDirection: 'column',
      }}>
      <TitleComponent text="Patt!" mb={'2vh'} />
      <TitleComponent text="Diese Seite befindet sich noch im Aufbau!" mt={0} mb={'2vh'} />
      <TitleComponent text="Besuche Stattdessen die Alte Seite" mt={0} mb={'2vh'} />
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <CustomButton
          sx={{
            bgcolor: 'success.main',
            '&:hover': {
              bgcolor: 'success.dark',
            },
          }}
          onClick={() =>
            (window.location.href = 'https://old.schachclub-kastellaun.de' + oldSitePath)
          }>
          Besuche die Alte Seite
        </CustomButton>
        <CustomButton
          sx={{
            mt: 1,
          }}
          onClick={() => navigate('/')}>
          Zurück zur Homepage
        </CustomButton>
      </Box>
    </Box>
  );
}

export default UnderConstructionPage;
