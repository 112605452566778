import { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import CalendarDay from './calendarDay.component';
import { calendarStyles } from './calendarStyles';
import api from 'functions/api';
import EventDto from 'dtos/event.dto';
import CustomIconButton from 'components/input/iconButton.component';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { AuthContext } from 'contexts/auth.context';
import { useNavigate } from 'react-router-dom';
import CustomButton from 'components/input/button.component';
import { ThemeContext } from 'contexts/theme.context';

function Calendar() {
  const navigate = useNavigate();
  const { colorMode } = useContext(ThemeContext);
  const { userHasRole } = useContext(AuthContext);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState<EventDto[]>([]);

  const daysOfWeek = [
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
    'Sonntag',
  ];

  const monthNames = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ];

  const currentMonthName = monthNames[currentDate.getMonth()];
  const styles = calendarStyles[currentMonthName];

  useEffect(() => {
    const startTimespan = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    startTimespan.setHours(0, 0, 0, 0);

    const endTimespan = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    endTimespan.setHours(23, 59, 59, 999);
    api
      .get('/events', {
        params: {
          startTimespan,
          endTimespan,
        },
      })
      .then((response) => {
        const eventsWithDates = response.data.map((event: EventDto) => ({
          ...event,
          start_date_time: new Date(event.start_date_time),
          end_date_time: new Date(event.end_date_time),
        }));
        setEvents(eventsWithDates);
      });
  }, [currentDate]);

  function getDaysInMonth(date: Date): number {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  }

  function getFirstDayOfMonth(date: Date): number {
    // getDay returns the day of the week starting with Sunday as 0 so this needs to be converted
    return (new Date(date.getFullYear(), date.getMonth(), 1).getDay() + 6) % 7;
  }

  function handlePrevMonth() {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  }

  function handleNextMonth() {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  }

  function handleDeleteEvent(id: string) {
    setEvents(events.filter((event) => event.id !== id));
  }

  function renderDays(): JSX.Element[] {
    const daysInMonth = getDaysInMonth(currentDate);
    const firstDayOfMonth = getFirstDayOfMonth(currentDate);
    const days: JSX.Element[] = [];

    // Add empty cells for days before the first day of the month
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(
        <CalendarDay
          key={`start-${i}`}
          disabled={true}
          sx={{
            display: { xs: 'none', md: 'flex' },
            borderRight: { md: i % 7 === 6 ? 'none' : '2px solid #000' },
          }}
        />,
      );
    }

    // Add cells for each day of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const dateOfDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
      const eventsOfDay = events
        .filter(
          (event) =>
            // Accept event if its today
            event.start_date_time.toLocaleDateString() === dateOfDay.toLocaleDateString() ||
            // Or if it started before today and its still ongoing
            (event.start_date_time <= dateOfDay && event.end_date_time > dateOfDay) ||
            // Omit events that end today at midnight (all-day event of the day before)
            (event.end_date_time.toLocaleTimeString() !== '00:00:00' &&
              event.end_date_time.toLocaleDateString() === dateOfDay.toLocaleDateString() &&
              event.start_date_time.toLocaleDateString() !== dateOfDay.toLocaleDateString()),
        )
        .sort((a, b) => a.start_date_time.getTime() - b.start_date_time.getTime());
      days.push(
        <CalendarDay
          key={day}
          day={day}
          date={dateOfDay}
          events={eventsOfDay}
          handleDeleteEvent={handleDeleteEvent}
          sx={{
            borderRight: { md: (day + firstDayOfMonth) % 7 === 0 ? 'none' : '2px solid #000' },
          }}
        />,
      );
    }

    // Add empty cells for days after the last day of the month
    const totalCells = firstDayOfMonth + daysInMonth;
    const remainingCells = totalCells % 7 === 0 ? 0 : 7 - (totalCells % 7);
    for (let i = 0; i < remainingCells; i++) {
      days.push(
        <CalendarDay
          key={`end-${i}`}
          disabled={true}
          sx={{
            display: { xs: 'none', md: 'flex' },
            borderRight: {
              md: (firstDayOfMonth + daysInMonth + i + 1) % 7 === 0 ? 'none' : '2px solid #000',
            },
          }}
        />,
      );
    }

    return days;
  }

  return (
    <Box sx={{ width: '100%', margin: '0 auto', textAlign: 'center' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          maxWidth: '300px',
          mx: 'auto',
          mb: 1,
        }}>
        <CustomIconButton
          onClick={handlePrevMonth}
          sx={{ color: colorMode == 'dark' ? 'white' : 'black' }}>
          <IoIosArrowBack />
        </CustomIconButton>
        <Typography variant="h6" fontSize={30} fontFamily={'BlinkMacSystemFont'}>
          {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
        </Typography>
        <CustomIconButton
          onClick={handleNextMonth}
          sx={{ color: colorMode == 'dark' ? 'white' : 'black' }}>
          <IoIosArrowForward />
        </CustomIconButton>
      </Box>
      {userHasRole('editor') && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
          <CustomButton
            onClick={() => navigate('/termine/verwalten')}
            sx={{
              bgcolor: 'success.main',
              '&:hover': {
                bgcolor: 'success.dark',
              },
            }}>
            Neuer Termin
          </CustomButton>
        </Box>
      )}
      <Box
        sx={{
          border: '2px solid #000',
          borderRadius: '8px',
          overflow: 'hidden',
          borderTop: { xs: 'none', md: '2px solid #000' },
        }}>
        <Grid container spacing={0} sx={{ display: { xs: 'none', md: 'flex' } }}>
          {daysOfWeek.map((day, i) => (
            <Grid item xs={12 / 7} key={day}>
              <Box
                sx={{
                  height: 40,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRight: { md: i > 5 ? 'none' : '2px solid #000' },
                  background: styles.header,
                  color: 'black',
                }}>
                {day}
              </Box>
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          spacing={0}
          sx={{ flexDirection: { xs: 'column', md: 'row' }, background: styles.day }}>
          {renderDays()}
        </Grid>
      </Box>
    </Box>
  );
}

export default Calendar;
