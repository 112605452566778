import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navigation/navbar.component';
import Home from './pages/home.page';
import AdminLoginPage from 'pages/admin-login/admin-login.page';
import { Box, Container, CssBaseline, ThemeProvider } from '@mui/material';
import BasePage from 'pages/base/base.page';
import ManagePostsPage from 'pages/news/managePosts.page';
import NewsPage from 'pages/news/news.page';
import { useContext } from 'react';
import NoAccessPage from 'pages/noAccess.page';
import { AuthContext } from 'contexts/auth.context';
import ConfirmDialog from 'components/dialog/dialog.component';
import { ThemeContext, LightTheme, DarkTheme } from 'contexts/theme.context';
import Footer from 'components/navigation/footer.component';
import RequestPasswordResetPage from 'pages/admin-login/requestPasswordReset.page';
import PasswordResetPage from 'pages/admin-login/passwordReset.page';
import ChangePasswordPage from 'pages/admin-login/changePassword.page';
import UnderConstructionPage from 'pages/underConstruction.page';
import ClubChampionshipPage from 'pages/clubChampionship.Page';
import useSecureLinks from 'useSecureLinks';
import EventsPage from 'pages/events/events.page';
import ManageEventsPage from 'pages/events/manageEvents.page';

function App() {
  useSecureLinks();
  const { userHasRole } = useContext(AuthContext);
  const { colorMode } = useContext(ThemeContext);

  return (
    <ThemeProvider theme={colorMode === 'light' ? LightTheme : DarkTheme}>
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}>
        <Router>
          <Navbar />
          <Container maxWidth="xl" sx={{ mt: '10px', mb: '1%', pt: '1%', flex: 1 }}>
            <Box>
              <Routes>
                <Route key="/" path="/" element={<Home />} />
                <Route key="/admin-login" path="/admin-login" element={<AdminLoginPage />} />
                <Route
                  key="admin-login/reset"
                  path="admin-login/reset"
                  element={<RequestPasswordResetPage />}
                />
                <Route
                  key="admin-login/reset-password"
                  path="admin-login/reset-password"
                  element={<PasswordResetPage />}
                />
                <Route
                  key="admin-login/passwort-ändern"
                  path="admin-login/passwort-ändern"
                  element={<ChangePasswordPage />}
                />
                <Route key="/news" path="/news" element={<NewsPage />} />
                <Route key="/news/:id" path="/news/:id" element={<NewsPage />} />
                <Route
                  key="/news/verwalten"
                  path="/news/verwalten"
                  element={userHasRole('editor') ? <ManagePostsPage /> : <NoAccessPage />}
                />
                <Route
                  key="/news/verwalten/:id?"
                  path="/news/verwalten/:id?"
                  element={userHasRole('editor') ? <ManagePostsPage /> : <NoAccessPage />}
                />
                <Route
                  key="/vereinsmeisterschaft/:year?"
                  path="/vereinsmeisterschaft/:year?"
                  element={<ClubChampionshipPage />}
                />
                <Route
                  key="/unsere-spieler"
                  path="/unsere-spieler"
                  element={<UnderConstructionPage oldSitePath="/unsere-spieler" />}
                />
                <Route key="/termine" path="/termine" element={<EventsPage />} />
                <Route
                  key="/termine/verwalten/:id?"
                  path="/termine/verwalten/:id?"
                  element={<ManageEventsPage />}
                />
                <Route key="*" path="*" element={<BasePage />} />
              </Routes>
            </Box>
          </Container>
          <Footer />
        </Router>
        <ConfirmDialog />
      </Box>
    </ThemeProvider>
  );
}

export default App;
