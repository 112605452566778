import { Box, BoxProps, Button } from '@mui/material';
import RteEditorComponent from 'components/rte-editor/rte-editor.component';
import { AuthContext } from 'contexts/auth.context';
import DefaultPageDto from 'dtos/defaultPage.dto';
import { useContext, useEffect, useState } from 'react';
import ButtonControlBar from '../../components/input/buttonControlBar.component';
import CustomButton from '../../components/input/button.component';
import { DialogContext } from 'contexts/dialog.context';
import TitleComponent from 'components/text/title.component';
import CustomIconButton from '../../components/input/iconButton.component';
import { Edit } from '@mui/icons-material';
import api from 'functions/api';
import NewsView from 'components/news/newsView.component';
import NotFoundPage from 'pages/notFound.page';

interface PageComponentProps extends BoxProps {
  route: string;
  title?: string;
}

function DefaultPage({ route, title, ...boxProps }: PageComponentProps) {
  const { userHasRole } = useContext(AuthContext);
  const [isEditMode, setIsEditMode] = useState(false);

  const [pageData, setPageData] = useState<DefaultPageDto>({
    id: '',
    content: '',
    post_filter: [],
    page_id: '',
    created_at: new Date(),
    created_by: '',
    updated_at: new Date(),
    updated_by: '',
  });
  const [initialPageData, setInitialPageData] = useState<DefaultPageDto>(pageData);
  const [rteResetKey, setRteResetKey] = useState(0);
  const [isContentEmpty, setIsContentEmpty] = useState(true);

  const { setDialogProps } = useContext(DialogContext);

  useEffect(() => {
    api
      .get('/page-content', { params: { path: route } })
      .then((response) => {
        if (response.data) {
          setPageData(response.data);
          setInitialPageData(response.data);
          setRteResetKey(rteResetKey ? 0 : 1);
          setIsContentEmpty(!response.data.content || /^<p>\s*<\/p>$/.test(response.data.content));
        }
      })
      .catch((error) => {
        console.error('Error loading page content: ' + location.pathname, error);
      });
  }, [route]);

  function onChange(content: string) {
    setPageData({ ...pageData, content: content });
    setIsContentEmpty(!content || /^<p>\s*<\/p>$/.test(content));
  }

  async function handleSubmit(confirm: boolean = false) {
    if (!confirm && pageData.id) {
      setDialogProps({
        open: true,
        message: 'Inhalt Aktualisieren?',
        content: 'Vorherige Versionen können nicht wiederhergestellt werden!',
        onConfirm: () => handleSubmit(true),
      });
    } else {
      if (!pageData.id) {
        await api.post('/page-content', pageData);
      } else {
        await api.patch('/page-content/' + pageData.page_id, pageData);
      }
      console.debug(pageData);
      setInitialPageData(pageData);
      setIsEditMode(false);
    }
  }

  function handleRevert(confirm: boolean = false) {
    if (!confirm && pageData.content !== initialPageData.content) {
      setDialogProps({
        open: true,
        message: 'Eingaben wirklich verwerfen?',
        content: 'Diese Aktion kann nicht rückgängig gemacht werden!',
        onConfirm: () => handleRevert(true),
      });
    } else {
      setPageData(initialPageData);
      setRteResetKey(rteResetKey ? 0 : 1);
      setIsContentEmpty(!initialPageData.content || /^<p>\s*<\/p>$/.test(initialPageData.content));
    }
  }

  function handleCancel(confirm: boolean = false) {
    if (!confirm && pageData.content !== initialPageData.content) {
      setDialogProps({
        open: true,
        message: 'Abbrechen Bestätigen',
        content: 'Nicht gespeicherte Änderungen gehen verloren!',
        onConfirm: () => handleCancel(true),
      });
    } else {
      setPageData(initialPageData);
      setRteResetKey(rteResetKey ? 0 : 1);
      setIsEditMode(false);
      setIsContentEmpty(!initialPageData.content || /^<p>\s*<\/p>$/.test(initialPageData.content));
    }
  }

  return (
    <>
      {pageData.page_id ? (
        <Box {...boxProps}>
          {title ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <TitleComponent text={title} />
              {userHasRole('editor') && !isEditMode && (
                <CustomIconButton
                  onClick={() => setIsEditMode(true)}
                  sx={{
                    bgcolor: 'success.main',
                    '&:hover': {
                      bgcolor: 'success.dark',
                    },
                  }}>
                  <Edit />
                </CustomIconButton>
              )}
            </Box>
          ) : (
            userHasRole('editor') &&
            !isEditMode && (
              <ButtonControlBar>
                <Button
                  onClick={() => setIsEditMode(true)}
                  sx={{
                    flexGrow: 1,
                    bgcolor: 'success.main',
                    '&:hover': {
                      bgcolor: 'success.dark',
                    },
                    color: 'black',
                    maxWidth: '200px',
                  }}>
                  Inhalt Bearbeiten
                </Button>
              </ButtonControlBar>
            )
          )}
          {!isContentEmpty || isEditMode ? (
            <Box sx={{ ml: '10px' }}>
              <RteEditorComponent
                content={pageData.content}
                editMode={isEditMode}
                onChange={onChange}
                resetKey={rteResetKey}
              />
              {userHasRole('editor') && isEditMode && (
                <ButtonControlBar sx={{ mt: '4vh' }}>
                  <CustomButton
                    onClick={() => handleCancel(false)}
                    sx={{
                      flexGrow: 1,
                      bgcolor: 'error.main',
                      '&:hover': {
                        bgcolor: 'error.dark',
                      },
                      color: 'black',
                    }}>
                    Abbrechen
                  </CustomButton>
                  <CustomButton
                    onClick={() => handleRevert()}
                    sx={{
                      flexGrow: 1,
                      bgcolor: 'warning.main',
                      '&:hover': {
                        bgcolor: 'warning.dark',
                      },
                      color: 'black',
                    }}>
                    Eingaben Verwerfen
                  </CustomButton>
                  <CustomButton
                    onClick={() => handleSubmit()}
                    sx={{
                      flexGrow: 1,
                      bgcolor: 'success.main',
                      '&:hover': {
                        bgcolor: 'success.dark',
                      },
                      color: 'black',
                    }}>
                    Speichern
                  </CustomButton>
                </ButtonControlBar>
              )}
              {pageData.post_filter?.length > 0 && (
                <NewsView
                  newsTitle="Berichte"
                  panelCount={4}
                  postFilter={pageData.post_filter}
                  showReadMore
                />
              )}
            </Box>
          ) : null}
        </Box>
      ) : (
        <NotFoundPage title={title} />
      )}
    </>
  );
}

export default DefaultPage;
