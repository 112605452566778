import { UserDto } from 'dtos/user.dto';
import { authenticate, login, logout } from 'functions/auth.functions';
import { createContext, useEffect, useState } from 'react';

interface AuthContextProps {
  user: UserDto | undefined;
  setUser: (user: UserDto) => void;
  userLogin: (email: string, password: string) => Promise<boolean>;
  userLogout: () => Promise<boolean>;
  userAuthenticate: () => Promise<UserDto | null>;
  userHasRole: (role: string) => boolean;
}

export const AuthContext = createContext<AuthContextProps>({
  user: undefined,
  setUser: () => {},
  userLogin: () => Promise.resolve(false),
  userLogout: () => Promise.resolve(false),
  userAuthenticate: () => Promise.resolve(null),
  userHasRole: () => false,
});

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
}): React.ReactElement | null => {
  const [user, setUser] = useState<UserDto | undefined>(undefined);

  useEffect(() => {
    if (user) return;
    authenticate().then((user) => {
      if (user) setUser(user);
    });
  }, []);

  async function userLogin(email: string, password: string): Promise<boolean> {
    const user = await login(email, password);
    // const user = await authenticate();
    if (user) {
      setUser(user);
      return true;
    }
    return false;
  }

  async function userLogout(): Promise<boolean> {
    const response = await logout();
    if (response) setUser(undefined);
    return response;
  }

  async function userAuthenticate(): Promise<UserDto | null> {
    return await authenticate();
  }

  function userHasRole(role: string): boolean {
    return user?.roles.includes(role) || false;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        userLogin,
        userLogout,
        userAuthenticate,
        userHasRole,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
