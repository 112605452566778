import { Box } from '@mui/material';
import PagesDto from 'dtos/pages.dto';
import api from 'functions/api';
import DefaultPage from 'pages/default/default.page';
import NoAccessPage from 'pages/noAccess.page';
import NotFoundPage from 'pages/notFound.page';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function BasePage() {
  const location = useLocation();
  const [page, setPage] = useState<PagesDto | null>(null);

  useEffect(() => {
    async function getPageLayout() {
      try {
        console.log('Fetching page layout for path:', location.pathname);
        const res = await api.get<PagesDto[]>('/pages', { params: { path: location.pathname } });
        if (res.data.length > 0) {
          setPage(res.data[0]);
        } else {
          setPage(null);
        }
      } catch {
        console.error('Page not found: ' + location.pathname);
        setPage(null);
      }
    }

    getPageLayout();
  }, [location.pathname]);

  function renderLayout(page: PagesDto) {
    switch (page.layout) {
      case 'default':
        return <DefaultPage route={location.pathname} title={page.title} />;
      default:
        return <NotFoundPage />;
    }
  }

  return (
    <Box>{page ? !page.admin_only ? renderLayout(page) : <NoAccessPage /> : <NotFoundPage />}</Box>
  );
}

export default BasePage;
