import {
  deDE,
  DesktopTimePicker,
  LocalizationProvider,
  TimePickerProps,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function CustomTimePicker(props: TimePickerProps<dayjs.Dayjs>) {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
      adapterLocale="de">
      <DesktopTimePicker label="Zeit" sx={{ width: 'fit-content' }} {...props} />
    </LocalizationProvider>
  );
}

export default CustomTimePicker;
